.main {
  width: 100%;
  height: 100vh;
  overflow: hidden;  
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.MuiListItem-button:hover {
  text-decoration: none;
  background-color: #303030 !important;
}
path.expand {
  transform: scaleY(1);
  stroke-width: 3px;
  transform-origin: right top;
  animation: expand 260ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
path.contract {
  transform: scaleY(3.862745);
  stroke-width: 0.77665px;
  transform-origin: right top;
  animation: contract 260ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

path.expandnoStork {
  transform: scaleY(1);
  transform-origin: right top;
  animation: expandnoStork 260ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
path.contractnoStork {
  transform: scaleY(3.862745);
  transform-origin: right top;
  animation: contractnoStork 260ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

path.expandnoFix {
  transform: scaleY(3.862745);
}
path.contractnoFix {
  transform: scaleY(1);
}

@keyframes expand {
  to {
    transform: scaleY(3.862745);
    stroke-width: 0.77665px;
  }
}
@keyframes contract {
  to {
    transform: scaleY(1);
    stroke-width: 3px;
  }
}

@keyframes expandnoStork {
  to {
    transform: scaleY(3.862745);
  }
}
@keyframes contractnoStork {
  to {
    transform: scaleY(1);
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 72px;
}
::-webkit-scrollbar-track {
  background: #030303;
}
::-webkit-scrollbar-thumb {
  background: #323232; 
  border-radius: 10px;
  margin: 0 2px;
}
::-webkit-scrollbar-thumb:hover {
  background: #4b4a4a; 
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 0;
}

.fixed.absolute {
  position: absolute;
}

*:focus {
  outline: none;
}

#project-popover .MuiPopover-paper {
  background: #30302F;
  border: 1px solid #504F4C;
  box-sizing: border-box;
  border-radius: 3px;
  /* font-family: 'Graphik'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 226.4%;
  color: #FFFFFF;
  margin-left: 3.25px;
  width: 199px;
}
#project-popover .MuiPopover-paper div:first-of-type {
  border-top: 0px!important;
}

#project-popover .MuiPopover-paper div:hover {
  background: #DB534D;
}

#menu-header label:hover {
  color: #FFFFFF;
}

#input-project-name::placeholder {
  opacity: 0.5;
}

#input-project-name:focus::placeholder {
  opacity: 1;
}

#btn-project:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #FF6059;
  color: #1C1414;
}

#btn-project:disabled {
  background: #393837;
  color: #626262;
}

#btn-project:focus, #btn-project:active  {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.14), rgba(0, 0, 0, 0.14)), #FF6059;
  color: #1C1414;
}

video {
  width: 100%;
  height: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-5 {
  margin-top: 5px;
}

.mr-20 {
  margin-right: 20px;
}

.m-0 {
  margin: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.cursor {
  cursor: pointer;
}

.height-0 {
  height: 0 !important;
}

.border-0 {
  border: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.ml-5 {
  margin-left: 5px;
}

.font-w-600 {
  font-weight: 600;
}

svg {
  pointer-events: all;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.product:hover {
  background: #262626;
  border: 1px solid #717171;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}